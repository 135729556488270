<template>
  <div class='open-app-btn' @click='openApp(article)'>
    <img :src='$baseInfo.LOGO_IMG' alt=''>
  </div>
</template>
<script>
import Bus from '../utils/bus';
export default {
  data() {
    return {
      article: Bus.CONTINUTETYPE_ARTICLE,
    }
  },
  methods:{
    openApp(continuteType) {
      this.$emit('openApp', continuteType)
    }
  }
}
</script>
<style lang="less" scoped>
  .open-app-btn {
    position: fixed;
    bottom: 10px;
    left: 50%;
    z-index: 999;
    width: 270px;
    height: 71px;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    background: url(https://cdn.9kd.com/kdnet/open_app_bg.png) no-repeat;
    background-size: 100% 100%;
    img {
      display: block;
      margin: 0 10px 0 25px;
      width: 43px;
      box-shadow: 0 0 1px #7E6F6C;
      border-radius: 7px;
    }
  }
</style>